import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>LeCode</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <div className="home-container-column">
        <div className="home-sections-column">
          <div className="home-header-column">
            <div className="home-header-info-column">
              <div className="home-header-row">
                <span className="home-info-text">OPORTUNIDADE ÚNICA</span>
              </div>
            </div>
          </div>
          <div className="home-body-column">
            <img
              alt="arrow"
              src="/playground_assets/svg115-qvx.svg"
              className="home-top-arrow"
            />
            <div className="home-body-row">
              <div className="home-community-logo-column">
                <img
                  alt="logo"
                  src="/playground_assets/variacaosemfundocolorida.svg"
                  loading="eager"
                  className="home-logo-img"
                />
              </div>
              <div className="home-community-access-column">
                <span className="home-desc-text">ELEVE SUAS HABILIDADES</span>
                <span className="home-title-text">COMUNIDADE LECODE</span>
                <a
                  href="https://forms.gle/rhreBf3YYLxdCAX79"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link"
                >
                  <div className="home-community-button-row Content">
                    <span className="home-community-access-text">
                      FaZER parte
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="home-social-column">
            <div className="home-social-row">
              <a
                href="https://www.instagram.com/gustavolevandowski.oficial"
                target="_blank"
                rel="noreferrer noopener"
                className="home-social-instagram-text"
              >
                <span>Instagram</span>
              </a>
              <a
                href="https://www.linkedin.com/in/levandowski/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-social-linkedin-text"
              >
                <span>LinkedIn</span>
              </a>
            </div>
          </div>
          <div className="home-footer-column">
            <img
              alt="arrow"
              src="/playground_assets/svg147-qqtb.svg"
              loading="lazy"
              className="home-bottom-arrow"
            />
            <div className="home-footer-copyright-column">
              <div className="home-footer-copyright-row">
                <span className="home-footer-copyright-text">
                  © 2023 LeCode - All Rights Reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
